import STATES, { StateType } from 'constants/States';
import { GenderSportType } from 'constants/GenderSports';
import {
  ARIZONA,
  ALABAMA,
  ARKANSAS,
  CALIFORNIA,
  FLORIDA,
  GEORGIA,
  IDAHO,
  ILLINOIS,
  INDIANA,
  IOWA,
  LOUISIANA,
  MARYLAND,
  MASSACHUSETTS,
  MICHIGAN,
  MINNESOTA,
  MISSISSIPPI,
  MISSOURI,
  MONTANA,
  NEBRASKA,
  NORTH_CAROLINA,
  OHIO,
  OKLAHOMA,
  OREGON,
  PENNSYLVANIA,
  SOUTH_CAROLINA,
  TEXAS,
  VERMONT,
  WASHINGTON
} from 'constants/States';

export const NEWS = 1;
export const SCORES = 2;
export const TEAMS = 3;
export const LEAGUES = 4;
export const PLAYOFFS = 5;
export const RANKINGS = 6;
export const TOP_PLAYERS = 7;
export const VIDEO_HIGHLIGHTS = 8;
export const PHOTOS = 9;
export const ATHLETE_OF_WEEK = 10;
export const RECRUITING = 11;
export const ISO_PODCAST = 12;
export const SCHOOLS = 13;
export const VIDEOS = 14;
export const PODCAST = 15;
export const STANDINGS = 16;

export const STATES_IN_NAV = STATES.filter(state => state.isAmerican);

export const SUBNAV_OPTIONS = [
  { id: NEWS, name: 'News', pathname: '' },
  { id: SCORES, name: 'Scores', pathname: 'scores' },
  { id: TEAMS, name: 'Teams', pathname: 'teams' },
  { id: PLAYOFFS, name: 'Playoffs', pathname: 'brackets' },
  { id: RANKINGS, name: 'Rankings', pathname: 'rankings' },
  { id: TOP_PLAYERS, name: 'Top Players', pathname: 'top-players' },
  { id: VIDEO_HIGHLIGHTS, name: 'Video ', pathname: 'video-highlights' },
  { id: PHOTOS, name: 'Photos', pathname: 'photos' },
  { id: ATHLETE_OF_WEEK, name: 'Athlete of the Week', pathname: 'athlete-of-the-week' },
  { id: RECRUITING, name: 'Recruiting', pathname: 'recruiting' },
  { id: ISO_PODCAST, name: '"The Iso" Podcast', pathname: 'podcast' },
  { id: SCHOOLS, name: 'Schools', pathname: 'schools' },
  { id: VIDEOS, name: 'Video', pathname: 'videos' },
  { id: PODCAST, name: 'Podcasts', pathname: 'podcast' },
  { id: STANDINGS, name: 'Standings', pathname: 'standings' }
];

export function hasPathId({ state, genderSport, pathId }: { state?: StateType; genderSport?: GenderSportType; pathId: number }): boolean {
  return getSubNavPathIds({ state, genderSport })?.includes(pathId);
}

export function getSubNavPathIds({ state, genderSport }: { state?: StateType; genderSport?: GenderSportType }) {
  const stateOptions = SUBNAV_STATE_MAPS[state?.abbrev || 'ALL'] || DEFAULT_MAP;

  return stateOptions[genderSport?.slug || 'all'] || [SCORES, TEAMS];
}

export function findSubNavOption(pathId: number) {
  return SUBNAV_OPTIONS.find(option => option.id === pathId);
}

const DEFAULT_MAP = {
  all: [NEWS, SCORES, SCHOOLS, PLAYOFFS],
  baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
  basketball: [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
  'girls-basketball': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
  'cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  football: [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
  golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
  tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'flag-football': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  wrestling: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
  'girls-wrestling': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
};

const FULL_CONTENT_MENU = [NEWS, SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS, TOP_PLAYERS, VIDEOS, PHOTOS, ATHLETE_OF_WEEK, RECRUITING];

const SUBNAV_STATE_MAPS = {
  ALL: {
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, RECRUITING, VIDEOS, PHOTOS, PODCAST],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS], // this is boys-basketball
    'girls-basketball': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS],
    'cross-country': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS], // this is boys-cross-country
    'girls-cross-country': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'field-hockey': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS],
    golf: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS], // boys-golf
    'girls-golf': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS], // boys-ice-hockey
    'girls-ice-hockey': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS], // boys-lacrosse
    'girls-lacrosse': [/*NEWS, */ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS], // boys-soccer
    'girls-soccer': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS], // boys-tennis
    'girls-tennis': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS], // girls-volleyball
    'water-polo': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS], // boys-water-polo
    'girls-water-polo': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'flag-football': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [ALABAMA]: {
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-cross-country': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'field-hockey': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [/*NEWS*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [/*NEWS,*/ TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [/*NEWS,*/ TEAMS, STANDINGS, PLAYOFFS]
  },
  [ARIZONA]: {
    all: [NEWS, SCORES, SCHOOLS, /*TOP_PLAYERS,*/ PLAYOFFS, VIDEO_HIGHLIGHTS, ATHLETE_OF_WEEK /*PHOTOS, ATHLETE_OF_WEEK, RECRUITING*/],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'beach-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [ARKANSAS]: {
    all: [NEWS, SCORES, SCHOOLS, /*TOP_PLAYERS,*/ PLAYOFFS, VIDEO_HIGHLIGHTS, /*PHOTOS,*/ ATHLETE_OF_WEEK, RECRUITING],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [CALIFORNIA]: {
    all: [NEWS, SCORES, SCHOOLS, /*TOP_PLAYERS,*/ PLAYOFFS, VIDEO_HIGHLIGHTS, PHOTOS, ATHLETE_OF_WEEK /*RECRUITING*/],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'beach-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'flag-football': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [FLORIDA]: {
    ...DEFAULT_MAP,
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU,
    'beach-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [GEORGIA]: {
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [IDAHO]: {
    all: [NEWS, SCORES, SCHOOLS, /*TOP_PLAYERS,*/ PLAYOFFS, VIDEO_HIGHLIGHTS, /*PHOTOS,*/ ATHLETE_OF_WEEK /*, RECRUITING*/],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [ILLINOIS]: {
    ...DEFAULT_MAP,
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [INDIANA]: {
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [IOWA]: {
    ...DEFAULT_MAP,
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [LOUISIANA]: {
    ...DEFAULT_MAP,
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU,
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS]
  },
  [MARYLAND]: {
    ...DEFAULT_MAP,
    wrestling: [NEWS, PLAYOFFS]
  },
  [MASSACHUSETTS]: {
    ...DEFAULT_MAP,
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    football: [NEWS, SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'boys-field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [MICHIGAN]: {
    ...DEFAULT_MAP,
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [MINNESOTA]: {
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [MISSISSIPPI]: {
    all: [NEWS, SCORES, SCHOOLS, /*TOP_PLAYERS,*/ PLAYOFFS, VIDEO_HIGHLIGHTS, /*PHOTOS,*/ ATHLETE_OF_WEEK, RECRUITING],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [MISSOURI]: {
    ...DEFAULT_MAP,
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [MONTANA]: {
    ...DEFAULT_MAP,
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    football: FULL_CONTENT_MENU
  },
  [NEBRASKA]: {
    ...DEFAULT_MAP,
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [NORTH_CAROLINA]: {
    ...DEFAULT_MAP,
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [OHIO]: {
    ...DEFAULT_MAP,
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [OKLAHOMA]: {
    ...DEFAULT_MAP,
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    football: [NEWS, SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS, RANKINGS]
  },
  [OREGON]: {
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-cross-country': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'field-hockey': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [PENNSYLVANIA]: {
    ...DEFAULT_MAP,
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [SOUTH_CAROLINA]: {
    ...DEFAULT_MAP,
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    football: FULL_CONTENT_MENU
  },
  [TEXAS]: {
    all: [NEWS, SCORES, SCHOOLS, PLAYOFFS, ATHLETE_OF_WEEK],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  },
  [VERMONT]: {
    ...DEFAULT_MAP,
    basketball: [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'girls-basketball': [NEWS, SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    football: [NEWS, SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'boys-volleyball': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    volleyball: [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    soccer: [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'girls-soccer': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'boys-ultimate-frisbee': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'girls-ultimate-frisbee': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS],
    'unified-basketball': [SCORES, TEAMS, STANDINGS, RANKINGS, PLAYOFFS]
  },
  [WASHINGTON]: {
    all: [NEWS, SCORES, SCHOOLS, /*TOP_PLAYERS,*/ PLAYOFFS, VIDEO_HIGHLIGHTS, /*PHOTOS,*/ ATHLETE_OF_WEEK, RECRUITING, ISO_PODCAST],
    baseball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    basketball: FULL_CONTENT_MENU,
    'girls-basketball': FULL_CONTENT_MENU,
    'cross-country': [SCORES, TEAMS, STANDINGS],
    'girls-cross-country': [SCORES, TEAMS, STANDINGS],
    'field-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    football: FULL_CONTENT_MENU,
    golf: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-golf': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-ice-hockey': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    lacrosse: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-lacrosse': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    soccer: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS], // boys-soccer
    'girls-soccer': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    softball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS],
    tennis: [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-tennis': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'boys-volleyball': [/*NEWS,*/ SCORES, TEAMS, STANDINGS, PLAYOFFS],
    volleyball: [NEWS, SCORES, TEAMS, STANDINGS, PLAYOFFS], // volleyball
    'water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS],
    'girls-water-polo': [SCORES, TEAMS, STANDINGS, PLAYOFFS]
  }
} as Record<string, Record<string, number[]>>;
